import React, { Fragment, useEffect, useState } from "react";
import { Grid, Box, MenuItem, Menu, Avatar, Typography, Divider, Button, AppBar, Toolbar, Badge } from "@mui/material";
import { NotificationsNoneTwoTone, NotificationsNone, SettingsOutlined, LockReset, PersonAdd } from "@mui/icons-material";
import colors from './../styles/colors';
import { PrimaryButton } from './../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import useAuth from './../hooks/useAuth';
import { styled, useTheme } from '@mui/material/styles';
import { SuccessToaster } from "../components/Toaster/Toaster";
import { ErrorToaster } from './../components/Toaster/Toaster';
import AuthServices from './../apis/Auth/AuthServices';
import ProfileServices from "../apis/Profile/ProfileServices";
import { baseUrl } from "../../axios";
import ResetPasswordDialog from "../components/Dialog/ResetPasswordDialog";

//* badge style For Notification
const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: 18,
    top: 6,
    border: `1px solid ${colors.white}`,
  },
}));

const Header = () => {

  const { userLogout, userName, picture, mail } = useAuth();

  const navigate = useNavigate()
  const [quoteDialog, setQuoteDialog] = useState(false);
  const [profile, setProfile] = useState('')
  // *Anchor Box
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    }
    else {
      setAnchorEl(event.currentTarget);
    }
  };

  const logout = async () => {
    const fcmToken = localStorage.getItem('fcmToken')
    try {
      const obj = {
        fcm_token: fcmToken
      }
      const { data } = await AuthServices.logout(obj)
      if (data === null) return
      userLogout()
      navigate('/login')
    }
    catch (error) {
      console.log("🚀 ~ file: Header.js:43 ~ logout ~ error:", error)
    }
  }

  const resetPassword = async (formData, reset) => {
    try {
      const obj = {
        oldPassword: formData.oldPass,
        newPassword: formData.newPass,
      }
      const { data, message, } = await AuthServices.reset(obj)
      if (data === null) return
      SuccessToaster(message)
      reset()
    }
    catch (error) {
      ErrorToaster(error)
    }
  }

  const getProfile = async () => {
    try {
      const { data } = await ProfileServices.getProfile()
      if (data === null) return
      setProfile(data.result)
    }
    catch (error) {
      ErrorToaster(error)
      console.log("🚀 ~ file: Header.js:101 ~ getProfile ~ error:", error)
    }
  }

  useEffect(() => {
    getProfile()
  }, [])


  return (
    <AppBar sx={{ mb: 20, bgcolor: colors.white, boxShadow: 0 }}>

      <ResetPasswordDialog open={quoteDialog} onClose={() => setQuoteDialog(!quoteDialog)} title={"Reset Password"} action={resetPassword} />

      <Toolbar>
        <Grid container py={2} px={3} justifyContent={"space-between"}>
          <Grid item xs={3}></Grid>
          <Grid item xs={9}>

            <Box sx={{
              display: "flex", alignItems: "center",
              cursor: "pointer", float: "right",
            }}>
              <Box
                onClick={(e) => { handleClick(e) }}
                sx={{
                  display: "flex", alignItems: "center", p: 1, borderRadius: "22px",
                  backgroundColor: open ? colors.primary : colors.lightGray,
                  "&:hover": {
                    backgroundColor: colors.primary, cursor: "pointer",
                    ".MuiSvgIcon-root": { color: colors.cloud },
                  },
                }}
              >

                <Avatar alt="profile" src={baseUrl + profile.picture} sx={{ width: 24, height: 24 }} />
                <SettingsOutlined
                  sx={{ color: open ? colors.lightGray : colors.secondary, fontSize: "26px", ml: 2 }}
                />
              </Box>
            </Box>
            {/* <Box sx={{
              display: "flex", alignItems: "center",
              cursor: "pointer", float: "right", pr: 0.5
            }}>
              <Box
                onClick={getNotifications}
                sx={{
                  display: "flex", alignItems: "center", p: 1, borderRadius: "22px",
                  backgroundColor: open ? colors.primary : colors.lightGray,
                  "&:hover": {
                    backgroundColor: colors.primary, cursor: "pointer",
                    ".MuiSvgIcon-root": { color: colors.cloud },
                  },
                }}
              >
                <StyledBadge badgeContent={notificationCount.unreadCount} color="error">
                  <NotificationsNoneTwoTone sx={{ color: colors.secondary, fontSize: "26px", }} />
                </StyledBadge>
              </Box>
            </Box> */}
          </Grid>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              width: '350px', px: 2, overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))", mt: 1.5,
              "& .MuiAvatar-root": { width: 60, height: 60, ml: 1, mr: 1, },
              "&:before": {
                content: '""', display: "block", position: "absolute", top: 0,
                right: 14, width: 10, height: 10, bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)", zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Typography sx={{ fontSize: '16px', fontWeight: 500, color: colors.black, p: 1.5 }}>
            User Profile
          </Typography>
          <Box onClick={() => navigate('/profile')}
            sx={{ display: 'flex', alignItems: "center", cursor: "pointer", "&:hover": { bgcolor: colors.lightGray, borderRadius: "10px" } }}>
            <Avatar src={baseUrl + profile?.picture} sx={{ borderRadius: '50px' }} />
            <Box>
              <Typography sx={{ fontSize: '18px', fontWeight: "bold" }}>{profile?.name?.charAt(0).toUpperCase() + profile?.name?.slice(1)}</Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 500, }}>
                {profile?.email}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 1 }} />
          <MenuItem sx={{ p: 1, borderRadius: "8px" }} onClick={() => { setQuoteDialog(!quoteDialog); }} >
            <Box sx={{ display: 'flex' }}>
              <LockReset sx={{ mx: 2, fontSize: "24px" }} />
              <Box >
                <Typography sx={{ fontSize: '1rem', }}> Change Password</Typography>
              </Box>
            </Box>
          </MenuItem>
          <Box sx={{ my: 1.5 }}>
            <PrimaryButton
              onClick={logout}
              // data-testId="logout-btn"
              title="logout"
              // onClick={logout}
              fullWidth={true}
            />
          </Box>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default Header