import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import BreadCrumbs from './../../components/BreadCrumbs/BreadCrumbs';
import OrderServices from '../../apis/Order/OrderServices';
//* Required Components
import { ErrorToaster, SuccessToaster } from './../../components/Toaster/Toaster';
import colors from '../../styles/colors';
import { getTimeDate } from '../../utils/DateFormate';
import { Loading } from './../../components/UI/Loader';
//* MUI Imports
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Recommend, RequestQuote, Done, Visibility, KeyboardArrowLeft, KeyboardArrowRight, } from '@mui/icons-material'
import { Paper, Box, Grid, Typography, tooltipClasses, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, IconButton } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import moment from 'moment';
import ProfileServices from '../../apis/Profile/ProfileServices';
import AuthServices from '../../apis/Auth/AuthServices';
import { useForm } from 'react-hook-form';
import { InputField } from '../../components/Fields/TextField';
import { PrimaryButton } from '../../components/Button/Button';
import AddressForm from '../../components/Dialog/AddressForm';

const Profile = () => {

  const navigate = useNavigate()
  const { pharmacyId } = useAuth();

  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm();

  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  // *For Dialog
  const [addressFormDialog, setAddressFormDialog] = useState(false);

  const updatePharmacy = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        pharmacy_id: pharmacyId,
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        latitude: latitude,
        longitude: longitude,
      }
      const { data, message } = await ProfileServices.updateProfile(obj);
      if (data === null) return
      SuccessToaster(message)
    }
    catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  const handleAddress = (data) => {
    try {
      setValue('address', data?.address)
      setLatitude(data.latitude)
      setLongitude(data.longitude)
    } catch (error) {
      console.log('file: create-pharmacy.js:50 => handleAddress => error:', error)
    }
  }

  const getProfile = async () => {
    setLoader(true)
    try {
      const { data } = await ProfileServices.getProfileDetail()
      if (data === null) return
      setValue('name', data.result?.name)
      setValue('email', data.result?.email)
      setValue('phone', data.result?.phone)
      setValue('address', data.result?.address?.address)
      setLatitude(data.result?.address?.latitude)
      setLongitude(data.result?.address?.longitude)
    }
    catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    getProfile()
  }, [])

  return (
    <Fragment>

      {/* ========== Address Form Dialog Box ========== */}
      <AddressForm open={addressFormDialog} onClose={() => setAddressFormDialog(false)} defaultData={{ address: getValues('address'), latitude: latitude, longitude: longitude }} save={(data) => { handleAddress(data); setAddressFormDialog(false) }} />

      <Box sx={{
        backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)"
      }} >
        <Grid container alignItems="center" justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">My Profile</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['My Profile']} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <Box sx={{ bgcolor: colors.white, p: 3, borderRadius: "12px", mt: 2 }}>
              <Typography component={"form"} onSubmit={handleSubmit(updatePharmacy)}>
                <Grid container spacing={2} alignItems="center">
                  {/* GST */}
                  <Grid item xs={12} md={6}>
                    <InputField
                      label="Pharmacy name"
                      error={errors?.name?.message}
                      register={register("name", {
                        required: 'Please enter name',
                      })}
                    />
                  </Grid>
                  {/* VAT */}
                  <Grid item xs={12} md={6}>
                    <InputField
                      label="Email"
                      error={errors?.email?.message}
                      register={register("email", {
                        required: 'Please enter email',
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ display: "flex", gap: '5px', alignItems: 'flex-start' }}>
                    {/* <Typography variant="subtitle1">
                  +971
                </Typography> */}
                    <InputField
                      variant="standard"
                      label="Phone"
                      inputProps={{ minLength: 9, maxLength: 9 }}
                      placeholder={"50 123 4567"}
                      error={errors?.phone?.message}
                      register={register("phone", {
                        required: 'Please enter phone number',
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ cursor: 'pointer' }} onClick={() => setAddressFormDialog(true)}>
                      <InputField
                        label="Address"
                        placeholder='Address'
                        disabled={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={errors?.address?.message}
                        register={register("address", {
                          required: 'Please enter address',
                        })}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>

                  <PrimaryButton
                    title={"Save changes"}
                    type="submit"
                    loading={loading}
                  />
                </Box>
              </Typography>
            </Box>
          </Fragment>
        )}
      </Box >
    </Fragment >
  )
}

export default Profile