import React from 'react';
// import { LoadingButton } from '@mui/lab';
import colors from './../../styles/colors';
import { Button, CircularProgress } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';

export const PrimaryButton = (props) => {
  return (
    <LoadingButton
      variant="contained"
      {...props}
      sx={{
        bgcolor: colors.primary,
        textTransform: 'capitalize',
        minWidth: '100px',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      }}
    >
      {props.loading ? <CircularProgress color="inherit" size={24} /> : props.title}
      {/* {props.title} */}
    </LoadingButton>
  );
}

export const SecondaryButton = (props) => {
  return (
    <Button
      variant="contained"
      {...props}
      sx={{
        bgcolor: colors.secondary,
        textTransform: 'capitalize',
        minWidth: '100px',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      }}
    >
      {props.title}
    </Button>
  );
}