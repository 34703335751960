import { get, post, patch, deleted } from '../index'
import ProfileRoutes from './Profile.Routes';

const ProfileServices = {
  updatePicture: async (obj) => {
    console.log("🚀 ~ file: ProfileServices.js:6 ~ updatePicture: ~ obj:", obj)
    const data = await post(ProfileRoutes.updatePicture, obj);
    return data
  },
  updateProfile: async (obj) => {
    const data = await patch(ProfileRoutes.updateProfile, obj);
    return data
  },
  getProfile: async () => {
    const data = await get(ProfileRoutes.getProfile);
    return data
  },
  getProfileDetail: async () => {
    const data = await get(ProfileRoutes.getProfileDetail);
    return data
  },
}

export default ProfileServices