import React from 'react';
import { FormControl, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { Error } from '../UI/Error';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/material.css'
// import { makeStyles } from '@mui/styles';


// import { Error } from './Error';

// const useStyles = makeStyles({
//   borderClass: {
//     "&.react-tel-input .form-control": {
//       borderColor: "#D32F2F",
//       border: "1px solid #D32F2F"
//     }
//   }
// })

export function InputField({ label, multiline, InputLabelProps, error, placeholder, register, type, readOnly, defaultValue, disable, rows, }) {


  return (
    <FormControl variant="standard" fullWidth  >
      <TextField
        disabled={disable}
        InputLabelProps={InputLabelProps}
        InputProps={{
          inputProps: { step: 0.001, min: 0 }
        }}
        sx={{
          mb: error ? "0px" : 2
        }}
        rows={rows}
        multiline={multiline}
        size={"small"}
        variant="outlined"
        label={label}
        placeholder={placeholder}
        defaultValue={defaultValue}
        type={type}
        error={error && true}
        {...register}
      />
      {error && (
        <Error message={error} />
      )}
    </FormControl>
  )
}

export function OutlineInputField({ defaultValue, label, error, register, endAdornment, updatedValue, type, size, onClick, disable }) {
  return (
    <FormControl size={"small"} fullWidth>
      <InputLabel error={error && true}>{label}</InputLabel>
      <OutlinedInput
        defaultValue={defaultValue}
        disabled={disable}
        onClick={onClick}
        endAdornment={endAdornment}
        label={label}
        type={type}
        sx={{
          mb: error ? "0px" : 2
        }}
        value={updatedValue}
        variant="outlined"
        error={error && true}
        {...register}
      />
      {error && (
        <Error message={error} />
      )}
    </FormControl>
  )
}
