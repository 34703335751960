import Profile from "../views/profile";
import Order from "./../views/Orders/Order";
import OrderDetail from "./../views/Orders/OrderDetail";

const AdminRoutes = [
  {
    path: "/",
    component: <Order />,
  },
  {
    path: "/order-detail/:id",
    component: <OrderDetail />,
  },
  {
    path: "/profile",
    component: <Profile />,
  },
];

export default AdminRoutes;
