import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import BreadCrumbs from './../../components/BreadCrumbs/BreadCrumbs';
import OrderServices from '../../apis/Order/OrderServices';
//* Required Components
import { ErrorToaster, SuccessToaster } from './../../components/Toaster/Toaster';
import colors from '../../styles/colors';
import { getTimeDate } from '../../utils/DateFormate';
import { Loading } from './../../components/UI/Loader';
//* MUI Imports
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Recommend, RequestQuote, Done, Visibility, KeyboardArrowLeft, KeyboardArrowRight, } from '@mui/icons-material'
import { Paper, Box, Grid, Typography, tooltipClasses, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, IconButton } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import moment from 'moment';
import { SecondaryButton } from '../../components/Button/Button';
import UserDocDialog from '../../components/Dialog/UserDocDialog';
//* Firebase import

//* Table Pagination ActionsComponent
function TablePaginationActions(props) {

  const theme = useTheme

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };



  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}

//* custom tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    textAlign: "center",
    background: "#0D253C",
    "&>.MuiTooltip-arrow": {
      "&:before": {
        color: "#0D253C"
      }
    }
  },
});

const tableHead = ['Order Id', 'Allocated At', 'Order Type', 'Total message', 'Documents', 'Action']

const Order = () => {

  const navigate = useNavigate()
  const { pharmacyId } = useAuth();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState("")
  const [rowsData, setRowsData] = useState([]);
  const [documents, setDocuments] = useState();
  const [docDialog, setDocDialog] = useState(false);

  // *For Loader
  const [loader, setLoader] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getOrders(newPage + 1, rowsPerPage)
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 2));
    setRowsPerPage(Math.floor(event.target.value))
    setPage(0);
    getOrders(1, event.target.value)
  };


  //* All Orders API Call
  const getOrders = async (newPage, rowsPerPage) => {
    setLoader(true)
    try {
      const { data } = await OrderServices.getOrders(pharmacyId, newPage ? newPage : 1, rowsPerPage ? rowsPerPage : "")
      if (data === null) return
      setRowsData(data.allocations)
      setCount(data.totalCount)
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoader(false)
    }
  }

  const handleViewDoc = (doc) => {
    try {
      setDocuments(doc)
      setDocDialog(true)
    } catch (error) {
      console.log('file: Order.js:127 => handleViewDoc => error:', error)
    }
  }

  useEffect(() => {
    getOrders()
  }, [])

  return (
    <Fragment>
      <UserDocDialog open={docDialog} onClose={() => setDocDialog(false)} action={documents} />
      <Box sx={{
        backgroundColor: colors.lightGray, p: 3, borderRadius: "12px", width: "calc(100vw - 370px)"
      }} >
        <Grid container alignItems="center" justifyContent="space-between"
          sx={{
            bgcolor: colors.white, p: 2, borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">Orders List</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <BreadCrumbs currentPage={['Orders']} />
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            <TableContainer component={Paper}
              sx={{
                '&::-webkit-scrollbar': {
                  bgcolor: colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer"
                },
                '&::-webkit-scrollbar-thumb': {
                  bgcolor: colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer"
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                  boxShadow: 0,
                }
              }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead >
                  <TableRow sx={{ bgcolor: colors.lightGray }}>
                    {tableHead.map((item, index) =>
                      <TableCell key={index} sx={{ fontWeight: "bold" }} >{item}</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsData.map((row, index) => (
                    <TableRow
                      key={index}
                    >
                      <TableCell component="th" scope="row" >
                        {row.order?.order_id}
                      </TableCell>
                      <TableCell>
                        {moment(row.createdAt).format('DD/MM/YYYY hh:mm a')}
                      </TableCell>
                      <TableCell>
                        {row.order?.medication.length > 0 ? 'Medication' : 'Prescription'}
                      </TableCell>
                      <TableCell>
                        {row.messageCount}
                      </TableCell>
                      <TableCell>
                        <SecondaryButton title="View Doc" onClick={() => handleViewDoc(row?.order?.user)} />
                      </TableCell>
                      <TableCell>
                        <Tooltip title="View" >
                          <IconButton onClick={() => navigate(`/order-detail/${row._id}`)}>
                            <Visibility sx={{ color: colors.completed }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Table sx={{ marginTop: "12px" }} >

              <TablePagination
                sx={{ borderBottom: "none", bgcolor: colors.white, borderRadius: "12px" }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={+count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Fragment>
        )
        }
      </Box >
    </Fragment >
  )
}

export default Order