import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, IconButton, Grid, Typography } from '@mui/material';
import { baseUrl } from '../../../axios';
import { Swiper, SwiperSlide } from "swiper/react";
//* Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
//* import required modules
import { Navigation } from "swiper/modules";

function UserDocDialog({ open, onClose, action, loading }) {

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      sx={{ '& .MuiDialog-paper': { width: '80%', height: "auto", borderRadius: 2, py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 } } }}
    >
      <IconButton data-testId="close-dialog" color="primary" onClick={() => onClose()} sx={{ position: 'absolute', right: 13, top: 13 }}>
        <Close />
      </IconButton>
      <DialogTitle sx={{ textAlign: "center", fontSize: '18px', fontWeight: 700 }}>Documents</DialogTitle>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} >
          <Swiper navigation={true} modules={[Navigation]} >
            {action?.nic_front &&
              <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
                <img src={baseUrl + action?.nic_front} alt="Nic Front" style={{ objectFit: "contain", height: '250px' }} />
              </SwiperSlide>
            }
            {action?.nic_back &&
              <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
                <img src={baseUrl + action?.nic_back} alt="Nic Back" style={{ objectFit: "contain", height: '250px' }} />
              </SwiperSlide>
            }
            {action?.insurance &&
              <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
                <img src={baseUrl + action?.insurance} alt="Insurance" style={{ objectFit: "contain", height: '250px' }} />
              </SwiperSlide>
            }
          </Swiper>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default UserDocDialog