import { Dashboard, ListAlt, Person } from "@mui/icons-material"
import colors from './styles/colors';
const styles = {
  iconSize: {
    p: 0.2,
    fontSize: "22px",
    color: colors.secondary,
  }
}

const Navigation = [
  {
    label: 'Orders',
    icon: <ListAlt sx={styles.iconSize} />,
    path: '/',
  },
  {
    label: 'Profile',
    icon: <Person sx={styles.iconSize} />,
    path: '/profile',
  },
]

export default Navigation