import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, IconButton, Grid, Typography, DialogContent, DialogActions, Button, FormControlLabel, Checkbox } from '@mui/material';
import colors from '../../styles/colors';
import { useEffect, useState } from 'react';
import { ErrorToaster } from '../Toaster/Toaster';
import { InputField } from '../Fields/TextField';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import OrderServices from '../../apis/Order/OrderServices';


function ReplyDialog({ open, onClose, id, getAllocationDetail }) {

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const handleSave = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        id: id,
        message: formData.reply
      }
      const { status, responseCode, data, message } = await OrderServices.SendMessage(obj)
      if (status === true && responseCode === 200) {
        onClose()
        reset()
        getAllocationDetail()
      }
    } catch (error) {
      console.log('file: AllocationDialog.js:45 => handleSave => error:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      data-testId="dialog-box"
      maxWidth="xs"
      open={open}
      sx={{ '& .MuiDialog-paper': { width: '90%', height: "auto", borderRadius: 2, py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 } } }}
    >
      <IconButton data-testId="close-dialog" color="primary" onClick={() => onClose()} sx={{ position: 'absolute', right: 13, top: 13 }}>
        <Close />
      </IconButton>
      <DialogTitle sx={{ textAlign: "center", fontSize: '18px', fontWeight: 700 }}>Reply</DialogTitle>
      <Box component={'form'} onSubmit={handleSubmit(handleSave)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputField
              label="Reply"
              rows={6}
              multiline={true}
              error={errors?.reply?.message}
              register={register("reply", {
                required: 'Please enter your reply.',
              })}
            />
          </Grid>
        </Grid>
        <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <LoadingButton
            loading={loading}
            variant='contained'
            type={'submit'}
            sx={{
              textTransform: 'capitalize',
              minWidth: '100px',
              boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
              fontSize: 12, fontWeight: 'bold',
              backgroundColor: colors.accepted,
              "&:hover": {
                backgroundColor: colors.accepted,
              }
            }}>
            Send
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default ReplyDialog