import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, IconButton, Grid, Typography, InputAdornment } from '@mui/material';
import { useForm } from 'react-hook-form';
import { PrimaryButton, SecondaryButton } from '../Button/Button';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputField, OutlineInputField } from './../Fields/TextField';
import { useState } from 'react';
import colors from '../../styles/colors';

function ResetPasswordDialog({ open, onClose, title, action, loading }) {

  // *For Show Password Toggle Icon
  const [showPassword, setShowPassword] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  return (
    <Dialog
      data-testId="dialog-box"
      maxWidth="xs"
      open={open}
      sx={{ '& .MuiDialog-paper': { width: '80%', height: "auto", borderRadius: 2, py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 } } }}
    >
      <IconButton data-testId="close-dialog" color="primary" onClick={() => onClose()} sx={{ position: 'absolute', right: 13, top: 13 }}>
        <Close />
      </IconButton>
      <DialogTitle sx={{ textAlign: "center", fontSize: '18px', fontWeight: 700 }}>{title}</DialogTitle>
      <Typography component={"form"} onSubmit={handleSubmit((formData) => action(formData, reset))}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} >
            <OutlineInputField
              label="Old Password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    sx={{ color: colors.darkMintGreen, fontSize: "18px" }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              error={errors?.password?.message}
              register={register("oldPass", {
                required: 'Please enter old password.',
              })
              }
            />
          </Grid>
          <Grid item xs={12} >
            <OutlineInputField
              label="New Password"
              type={showPass ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPass(!showPass)}
                    edge="end"
                    sx={{ color: colors.darkMintGreen, fontSize: "18px" }}
                  >
                    {showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              error={errors?.password?.message}
              register={register("newPass", {
                required: 'Please enter new password.',
              })
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <PrimaryButton
              data-testId="yes-btn"
              title="Reset"
              loading={loading}
              type="submit"
              onClick={() => onClose()}
            />
          </Grid>
        </Grid>
      </Typography>
    </Dialog>
  )
}

export default ResetPasswordDialog