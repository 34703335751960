import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { Box, Divider, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import Images from '../../assets/Images'
import colors from '../../styles/colors'
import { ErrorToaster, SuccessToaster } from '../../components/Toaster/Toaster'
import { InputField, OutlineInputField } from '../../components/Fields/TextField';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PrimaryButton } from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import AuthServices from '../../apis/Auth/AuthServices';
import useAuth from './../../hooks/useAuth';

const Login = () => {
  const navigate = useNavigate()
  const { userLogin } = useAuth()
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  // *For Show Password Toggle Icon
  const [showPassword, setShowPassword] = useState(false);

  const onLogin = async (formData) => {
    setLoading(true)
    try {
      const obj = {
        email: formData.email,
        password: formData.password
      }
      const { status, responseCode, data, message } = await AuthServices.login(obj)
      if (status === true && responseCode === 200) {
        SuccessToaster(message)
        localStorage.setItem('token', data.token)
        userLogin(data)
        navigate('/')
      }
    }
    catch (error) {
      ErrorToaster(error)
    }
    finally {
      setLoading(false)
    }
  }
  return (
    <Grid container alignItems={'center'} sx={{ height: "100vh" }}>
      <Grid item xs={12} md={6}>
        <Box sx={{ p: 3, textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          <img src={Images.logo} width="30%" alt="logo" />
          <Box>
            <img src={Images.banner} width="70%" alt="login" />
          </Box>
          <Typography variant="h4" sx={{ color: colors.black, fontWeight: "bold" }}> Welcome</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            boxShadow: "rgba(147, 186, 191, 1) 0px 48px 100px 0px",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }} >
          <Box sx={{ textAlign: "center", p: 3, borderRadius: 2, width: .7, boxSizing: "border-box" }}>
            <Typography variant="h4" sx={{ color: colors.black, my: 2 }}>
              Login
            </Typography>
            <Divider sx={{ mb: 1.5, bgcolor: colors.primaryLight }} />
            <Typography component={"form"} onSubmit={handleSubmit(onLogin)}>
              <Box sx={{ mt: 2, display: "flex", flexDirection: 'column' }}>
                <InputField
                  label="Email"
                  type="email"
                  error={errors?.email?.message}
                  register={register("email", {
                    required: 'Please enter your email.',
                    pattern: {
                      // value: emailRegex,
                      message: 'Please enter a valid email address',
                    }
                  })}
                />
                <OutlineInputField
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        sx={{ color: colors.darkMintGreen, fontSize: "18px" }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={errors?.password?.message}
                  register={register("password", {
                    required: 'Please enter password.',
                  })
                  }
                />
              </Box>
              <PrimaryButton
                // data-testId="login-btn"
                title="Login"
                type='submit'
                loading={loading ? "loading" : null}
                fullWidth={true}
              />
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Login