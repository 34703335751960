const OrderRoutes = {
  getOrders: "/orders/allocated/by",

  quoteOrders: "/orders/quote",
  getOrderDetails: "/orders/orderbyid",
  orderStatus: "/status",
  completeOrder: "/orders/delivered",
  getAllocationDetail: "/orders/allocated/details",
  SendMessage: "/orders/allocated/sendMessage",
}

export default OrderRoutes