import { get, post, patch, deleted } from '../index'
import OrderRoutes from './Order.Routes';

const OrderServices = {
  getOrders: async (pharmacyId, page, rowsPerPage) => {
    const data = await get(OrderRoutes.getOrders + `?pharmacy_id=${pharmacyId}&perPage=${rowsPerPage}&page=${page}`)
    return data
  },

  quoteOrder: async (obj) => {
    const data = await post(OrderRoutes.quoteOrders, obj);
    return data
  },
  getOrderDetails: async (id) => {
    const data = await get(OrderRoutes.getOrderDetails + `?id=${id}`)
    return data
  },
  orderStatus: async () => {
    const data = await get(OrderRoutes.orderStatus)
    return data
  },
  completeOrder: async (id) => {
    const data = await post(OrderRoutes.completeOrder + `?id=${id}`)
    return data
  },

  getAllocationDetail: async (id) => {
    const data = await get(OrderRoutes.getAllocationDetail + `?id=${id}`)
    return data
  },
  SendMessage: async (obj) => {
    const data = await post(OrderRoutes.SendMessage, obj)
    return data
  },
}

export default OrderServices