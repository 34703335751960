import React, { Fragment } from 'react'
import Header from './Header';
import { Box } from '@mui/material';
import SideNav from './SideNav';
import { Outlet } from 'react-router-dom';

export const Layout = ({ children }) => {
  return (
    <Fragment>

      {/* ========== Header ========== */}

      <Header />

      <Box sx={{ display: "flex" }}>

        {/* ========== SideNav ========== */}
        <Box>
          <SideNav />
        </Box>

        <Box sx={{ mt: "100px", mx: 2, flexGrow: 1 }}>
          <Outlet />
        </Box>

      </Box>

    </Fragment>
  )
}
