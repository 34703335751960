const Images = {
  logo: require("./images/logo.png"),
  loginImage: require("./images/cimage.jpg"),
  homeTitle: require("./images/HomeTitle.png"),
  notFound: require("./images/No-Image.png"),
  capsule: require("./images/capsules.png"),
  heart: require("./images/heart.png"),
  user: require("./images/user_avatar.png"),
  dUser: require("./images/dUser.jpg"),
  banner: require("./images/banner.png"),
  navImg: require("./images/navImg.png"),
  google: require("./images/google.png"),
  iphone: require("./images/iphone.png"),
  prescript: require("./images/prescript.png"),
  map: require("./images/map.png"),
  instagram: require("./images/instagram.png"),
  youtube: require("./images/youtube.png"),
  twit: require("./images/twitter.png"),
  wApp: require("./images/whatsapp.png"),
  linkedIn: require("./images/linkedin.png"),
  snapChat: require("./images/snapchat.png"),
  tikTok: require("./images/tik-tok.png"),
};

export default Images;

export const Loader = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#1890FF" stroke="none">
        <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51" />
      </path>
    </svg>
  )
}
